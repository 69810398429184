<!-- 当前用户被下单的服务 -->
<template>
  <div class="tab">
    <div class="tab_table">
      <el-table
        :data="tableData"
        :header-cell-style="headerCellStyle"
        @row-click="rowClick"
        v-loading="loading">
        <el-table-column
          label="订单编号"
          prop="orderNo">
        </el-table-column>
        <el-table-column
          label="产品名称"
          prop="title">
        </el-table-column>
        <el-table-column
          label="下单用户"
          prop="buyerName">
        </el-table-column>
        <!-- <el-table-column
          label="订单类型"
          prop="orderType">
          <template slot-scope="scope">
            <p>
              {{
                {
                  service: '服务订单',
                  service_chain: '服务链订单'
                }[scope.row.orderType]
              }}
            </p>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          label="价格"
          prop="totalAmount">
          <template slot-scope="scope">
            <p
              class="tw-text-14px tw-font-bold"
              style="color:#303133;">
              {{ scope.row.totalAmount > 0 ? scope.row.totalAmount : '面议' }}
            </p>
          </template>
        </el-table-column> -->
        <!-- <el-table-column
          label="下单时间"
          prop="createdAt">
        </el-table-column> -->
        <el-table-column
          label="订单状态"
          prop="logicStatus">
          <template slot-scope="scope">
            <el-tag :type="elTags(scope.row).type">
              {{elTags(scope.row).label}}
            </el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <template v-if="scope.row.orderType === 'service' && scope.row.logicStatus === globalVar.ORDER_STATUS_0">
              <el-button
                type="text"
                @click.stop="onConfirmOrder(scope.row, 'confirmed')">
                确认
              </el-button>
              <el-button
                type="text"
                @click.stop="onConfirmOrder(scope.row, 'cancelled')">
                取消
              </el-button>
            </template>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="tab_pagination tw-flex tw-justify-center tw-items-center">
      <el-pagination
        hide-on-single-page
        background
        @current-change="pageNumChange"
        :current-page.sync="pagination.pageNum"
        :page-size="pagination.pageSize"
        layout="prev, pager, next, jumper"
        :total="pagination.total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import * as api from '@/api'
import globalVar from '@/configs/globalVar'

export default {
  data () {
    return {
      loading: false,
      tableData: [],
      pagination: {
        pageNum: 1,
        pageSize: 10,
        total: 0
      } // 分页对象
    }
  },
  computed: {
    headerCellStyle () {
      return {
        backgroundColor: '#F7F8F9',
        color: '#909399',
        fontWeight: 'bold'
      }
    },
    elTags () {
      return (item) => {
        return {
          type: {
            [globalVar.ORDER_STATUS_0]: 'warning',
            [globalVar.ORDER_STATUS_1]: 'success',
            [globalVar.ORDER_STATUS_2]: 'info',
            [globalVar.ORDER_STATUS_3]: '',
            [globalVar.ORDER_STATUS_4]: 'danger'
          }[item.logicStatus],
          label: {
            [globalVar.ORDER_STATUS_0]: '已下单',
            [globalVar.ORDER_STATUS_1]: '进行中',
            [globalVar.ORDER_STATUS_2]: '已完成',
            [globalVar.ORDER_STATUS_3]: '已确认',
            [globalVar.ORDER_STATUS_4]: '已取消'
          }[item.logicStatus]
        }
      }
    }
  },
  mounted () {
    this.getTableData(1)
  },
  methods: {
    getTableData (pageNum) {
      this.loading = true
      api.getSellerOrderList({
        pageNum,
        pageSize: this.pagination.pageSize
      }).then(res => {
        if (res.data.code === 0) {
          this.pagination.pageNum = res.data.data.currentPage
          this.pagination.total = res.data.data.total
          this.tableData = res.data.data.list
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      }).finally(() => {
        this.loading = false
      })
    },
    pageNumChange (page) {
      this.getTableData(page)
    },
    rowClick (row, column, event) {
      const { href } = this.$router.resolve({
        path: `/service-order/order-detail/${row.id}`
      })

      window.open(href, '_blank')
    },
    onConfirmOrder (row, type) {
      this.$confirm('请确认你的操作!', '确认', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        api.confirmServiceOrder(row.id, type).then(res => {
          if (res.data.code === 0) {
            this.$message.success('操作成功')
            this.getTableData(this.pagination.pageNum)
          } else {
            this.$message.error(res.data.message)
          }
        }).catch(err => {
          console.log(err)
          this.$message.error('请求出错')
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.tab {
  &_op {
    padding: 10px 0;
  }
  &_table {
    padding: 10px 0;
  }
  &_pagination {
    margin: 20px 0;
    height: 40px;
  }
}
</style>
