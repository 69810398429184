<template>
  <el-dialog
    :title="{
      1: '订单评价',
      2: ''
    }[step]"
    :visible.sync="$visible"
    width="570px"
    :show-close="false"
    @open="onOpen">
    <div
      v-if="step === 1"
      class="tw-mx-20px">
      <el-form
        :model="formData"
        :rules="rules"
        :disabled="disabled"
        ref="form"
        size="small"
        label-position="left"
        label-width="110px">
        <el-form-item
          label="服务质量"
          prop="serviceQuality">
          <div class="tw-flex tw-items-center" style="height:32px;">
            <el-rate v-model="formData.serviceQuality" allow-half></el-rate>
          </div>
        </el-form-item>
        <el-form-item
          label="服务效果"
          prop="serviceEffect">
          <div class="tw-flex tw-items-center" style="height:32px;">
            <el-rate v-model="formData.serviceEffect" allow-half></el-rate>
          </div>
        </el-form-item>
        <el-form-item
          label="服务态度"
          prop="serviceAttitude">
          <div class="tw-flex tw-items-center" style="height:32px;">
            <el-rate v-model="formData.serviceAttitude" allow-half></el-rate>
          </div>
        </el-form-item>
        <el-form-item
          label="评价内容"
          prop="content">
          <el-input
            v-model="formData.content"
            type="textarea" maxlength="300" show-word-limit
            :rows="4">
          </el-input>
        </el-form-item>
      </el-form>
    </div>
    <div v-if="step === 2" class="success">
      <img class="success_img" src="../../assets/images/create_require_success.png" alt="">
      <p class="success_title tw-text-16px tw-font-bold">评价发布成功</p>
      <p class="success_tips tw-text-14px">感谢您的评价，谢谢您的使用！</p>
    </div>
    <div slot="footer">
      <template v-if="step === 1">
        <el-button
          size="small"
          @click="onCancel">
          取消
        </el-button>
        <el-button
          v-if="!disabled"
          type="primary"
          size="small"
          :loading="btnLoading"
          @click="onConfirm">
          提交
        </el-button>
      </template>
      <template v-if="step === 2">
        <el-button
          size="small"
          @click="onCancel">
          关闭
        </el-button>
      </template>
    </div>
  </el-dialog>
</template>

<script>
import * as api from '@/api'

export default {
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    orderId: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      step: 1, // 分步
      disabled: true,
      formData: {
        serviceQuality: 5, // 服务质量
        serviceEffect: 5, // 服务效果
        serviceAttitude: 5, // 服务态度
        content: '' // 评价内容
      },
      rules: {
        serviceQuality: [
          { required: true, message: '请选择服务质量评分', trigger: 'blur' }
        ],
        serviceEffect: [
          { required: true, message: '请选择服务效果评分', trigger: 'blur' }
        ],
        serviceAttitude: [
          { required: true, message: '请选择服务态度评分', trigger: 'blur' }
        ],
        content: [
          { required: true, message: '请输入评价内容', trigger: 'blur' }
        ]
      },
      btnLoading: false
    }
  },
  computed: {
    $visible: {
      get () {
        return this.visible
      },
      set (val) {
        this.$emit('update:visible', val)
      }
    }
  },
  methods: {
    onOpen () {
      this.getCommentInfo(this.orderId)
    },
    getCommentInfo (orderId) {
      api.getOrderCommentInfo(orderId).then(res => {
        if (res.data.code === 0) {
          if (JSON.stringify(res.data.data) === '{}') {
            this.disabled = false
          } else {
            this.disabled = true
            for (const key in this.formData) {
              if (Object.hasOwnProperty.call(this.formData, key)) {
                if (key === 'content') {
                  this.formData[key] = res.data.data[key]
                } else {
                  this.formData[key] = res.data.data[key] / 2
                }
              }
            }
          }
        } else {
          this.$message.error(res.data.message)
        }
      }).catch(err => {
        console.log(err)
        this.$message.error('请求出错')
      })
    },
    onCancel () {
      if (this.$refs.form) {
        this.$refs.form.resetFields()
      }
      this.$visible = false
      this.step = 1
    },
    onConfirm () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.btnLoading = true
          api.addOrderComment(this.orderId, {
            serviceQuality: this.formData.serviceQuality * 2,
            serviceEffect: this.formData.serviceEffect * 2,
            serviceAttitude: this.formData.serviceAttitude * 2,
            content: this.formData.content
          }).then(res => {
            if (res.data.code === 0) {
              this.step = 2
            } else {
              this.$message.error(res.data.message)
            }
          }).catch(err => {
            console.log(err)
            this.$message.error('请求出错')
          }).finally(() => {
            this.btnLoading = false
          })
        } else {
          console.log('error submit!!')
          return false
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.success {
  text-align: center;
  // &_img {
  // }
  &_title {
    color: #6AC244;
    line-height: 40px;
    margin-bottom: 20px;
  }
  &_tips {
    color: #909399;
    line-height: 20px;
  }
}
</style>
