<template>
  <div class="panel">
    <div class="panel_header tw-flex tw-justify-between tw-items-center">
      <page-header title="服务订单" />
    </div>
    <el-tabs
      v-if="role !== globalVar.USER_ROLE_0"
      v-model="activeTab">
      <el-tab-pane
        label="购买的服务"
        name="1">
      </el-tab-pane>
      <el-tab-pane
        label="发布的服务"
        name="2">
      </el-tab-pane>
    </el-tabs>

    <!-- 购买的服务 -->
    <my-order v-if="activeTab === '1'"></my-order>
    <!-- 发布的服务 -->
    <user-order v-if="activeTab === '2'"></user-order>
  </div>
</template>

<script>
import myOrder from './my-order'
import userOrder from './user-order'

export default {
  components: {
    myOrder,
    userOrder
  },
  data () {
    return {
      activeTab: '1'
    }
  },
  methods: {

  }
}
</script>

<style lang="scss" scoped>
.panel {
  width: 100%;
  min-width: 1030px;
  min-height: 100%;
  padding: 0 30px;
  background: #FFFFFF;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.06);
  border-radius: 4px;
  overflow: auto;
  &_header {
    padding: 20px 0;
  }
}
</style>
